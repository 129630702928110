import React from "react";
import { motion } from "framer-motion";
import tw, { styled } from "twin.macro";
import { css } from "styled-components";
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { useParams } from "react-router-dom";
import { blenderProducts } from "../../helpers/categoryProducts";

// Styled Components
const ProductPageContainer = tw.div`max-w-screen-lg mx-auto my-16 p-8 rounded-lg shadow-lg bg-gradient-to-b from-blue-100 to-white`;
const ProductContainer = tw.div`grid grid-cols-1 md:grid-cols-2 gap-12 items-start`;
const ImageContainer = styled.div`
  ${(props) =>
    css`
      background-image: url("${props.imageSrc || "path/to/default-image.jpg"}");
    `}
  ${tw`w-full h-96 bg-center bg-cover rounded-lg shadow-xl`}
`;
const InfoContainer = tw.div`space-y-6`;
const ProductTitle = tw.h1`text-5xl font-extrabold text-blue-900 drop-shadow-lg`;
const ProductModel = tw.h2`text-2xl font-semibold text-gray-500`;
const ProductDescription = tw.p`text-lg text-gray-600`;
const SpecificationsTitle = tw.h3`text-2xl font-semibold text-blue-700 mt-4 border-b-2 border-blue-300 pb-2`;
const ProductSpecifications = tw.ul`list-disc list-inside text-gray-700 text-lg`;
const DetailItem = tw.li`py-1`;
const Button = tw(
  motion.button
)`px-8 py-3 rounded-lg bg-gradient-to-r from-purple-500 to-indigo-500 hover:bg-gradient-to-l hover:from-indigo-500 hover:to-purple-500 text-white text-lg mt-6 shadow-lg transition-transform transform hover:scale-105`;

export default function BlenderProductPage() {
  const { id } = useParams();

  // Find the product by id, using parseInt to convert id to number
  const product = blenderProducts.find((item) => item.id === parseInt(id)) || null;

  // If product is not found
  if (!product) {
    return (
      <>
        <Header />
        <ProductPageContainer>
          <h2>Product not found</h2>
        </ProductPageContainer>
      </>
    );
  }

  return (
    <>
      <Header />

      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        <ProductPageContainer>
          <ProductContainer>
            {/* Product Image */}
            <ImageContainer imageSrc={product.imageSrc} />

            {/* Product Info */}
            <InfoContainer>
              <ProductTitle>{product.name || "No Product Name Available"}</ProductTitle>
              <ProductModel>Model: {product.model}</ProductModel>
              <ProductDescription>{product.description || "No Description Available"}</ProductDescription>

              {/* Product Specifications */}
              <SpecificationsTitle>Product Specifications:</SpecificationsTitle>
              <ProductSpecifications>
                <DetailItem>
                  <strong>Pcs per Carton:</strong> {product.pcsPerCtn || "N/A"}
                </DetailItem>
                <DetailItem>
                  <strong>Gift Box Size:</strong> {product.giftBoxSize || "N/A"}
                </DetailItem>
                <DetailItem>
                  <strong>Carton Size:</strong> {product.cartonSize || "N/A"}
                </DetailItem>
                <DetailItem>
                  <strong>Gross Weight:</strong> {product.gw} kg
                </DetailItem>
                <DetailItem>
                  <strong>Net Weight:</strong> {product.nw} kg
                </DetailItem>
              </ProductSpecifications>

              {/* Quantity in Containers */}
              <SpecificationsTitle>Container Quantities:</SpecificationsTitle>
              <ProductSpecifications>
                <DetailItem>
                  <strong>20GP:</strong> {product.qty20GP || "N/A"} units
                </DetailItem>
                <DetailItem>
                  <strong>40GP:</strong> {product.qty40GP || "N/A"} units
                </DetailItem>
                <DetailItem>
                  <strong>40HQ:</strong> {product.qty40HQ || "N/A"} units
                </DetailItem>
              </ProductSpecifications>

              {/* Call to Action Button */}
              <Button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => (window.location.href = "/contact-us")}
              >
                Get a Quote
              </Button>
            </InfoContainer>
          </ProductContainer>
        </ProductPageContainer>
      </motion.div>

      <Footer />
    </>
  );
}
export const electronics = [
  {
    id: "1",
    name: "Snack Maker",
    model: "PGT-1310B",
    makes: "6 brownies",
    imageSrc: "https://legacy-lifeline.b-cdn.net/electronics/pgt-1310b.png",
    specifications: ["110-120V 60Hz 900W", "220-240V 50Hz 900W"],
    packingDetails: {
      pcsPerCtn: null,
      giftBoxSize: null,
      cartonSize: null,
      gw: null,
      nw: null,
      pcs20GP: null,
      pcs40GP: null,
      pcs40HQ: null,
    },
  },
  {
    id: "2",
    name: "Sandwich Maker",
    description: "",
    description: "",
    model: "SW-029",
    makes: null,
    imageSrc: "https://legacy-lifeline.b-cdn.net/electronics/sw-029.png",
    specifications: ["110-120V 60Hz 700W", "220-240V 50Hz 750W"],
    packingDetails: {
      pcsPerCtn: "6pcs/Ctn",
      giftBoxSize: "26 x 10.3 x 24.8 Cm",
      cartonSize: "32.5 x 27 x 51.4 Cm",
      gw: "8.3 KGS",
      nw: "7.5 KGS",
      pcs20GP: "3690pcs/20GP",
      pcs40GP: "7680pcs/40GP",
      pcs40HQ: "9000pcs/40HQ",
    },
  },
  {
    id: "3",
    name: "Sandwich Maker",
    description: "",
    model: "SW-056/SW-056A",
    makes: null,
    imageSrc: "https://legacy-lifeline.b-cdn.net/electronics/sw-056.png",
    specifications: ["110-120V 60Hz 700W", "220-240V 50Hz 700W"],
    packingDetails: {
      pcsPerCtn: "6pcs/Ctn",
      giftBoxSize: "24 x 11.2 x 23.5 Cm",
      cartonSize: "35 x 25.5 x 49 Cm",
      gw: "9.2 KGS",
      nw: "8.3 KGS",
      pcs20GP: "3966pcs/20GP",
      pcs40GP: "8100pcs/40GP",
      pcs40HQ: "9300pcs/40HQ",
    },
  },
  {
    id: "4",
    name: "Omelet Maker",
    description: "",
    model: "PGT-1716",
    makes: null,
    imageSrc: "https://legacy-lifeline.b-cdn.net/electronics/pgt-1716.png",
    specifications: ["120V 60Hz 800W"],
    packingDetails: {
      pcsPerCtn: "8pcs/Ctn",
      giftBoxSize: "36.7 x 10.5 x 14.5 Cm",
      cartonSize: "43.5 x 38.2 x 31 Cm",
      gw: "9.0 KGS",
      nw: "7.2 KGS",
      pcs20GP: "4320pcs/20GP",
      pcs40GP: "9000pcs/40GP",
      pcs40HQ: "10520pcs/40HQ",
    },
  },
  {
    id: "5",
    name: "Griddle Grill",
    description: "",
    model: "SW-088/PGT-1202",
    makes: null,
    imageSrc: "https://legacy-lifeline.b-cdn.net/electronics/pgt-1202.png",
    specifications: ["110-120V 60Hz 600W", "220-240V 50Hz 700W"],
    packingDetails: {
      pcsPerCtn: "6pcs/Ctn",
      giftBoxSize: "26.5 x 13 x 23.8 Cm",
      cartonSize: "54 x 41 x 25.8 Cm",
      gw: "11.2 KGS",
      nw: "10.2 KGS",
      pcs20GP: "2946pcs/20GP",
      pcs40GP: "5892pcs/40GP",
      pcs40HQ: "7050pcs/40HQ",
    },
  },
  {
    id: "6",
    name: "Griddle Grill",
    description: "",
    model: "PGT-1218",
    makes: null,
    imageSrc: "https://legacy-lifeline.b-cdn.net/electronics/pgt-1218.png",
    specifications: ["110-120V 60Hz 700W", "220-240V 50Hz 700W"],
    packingDetails: {
      pcsPerCtn: "4pcs/Ctn",
      giftBoxSize: "26 x 16.5 x 31.5 Cm",
      cartonSize: "67.5 x 27.5 x 33 Cm",
      gw: "10.2 KGS",
      nw: "9.2 KGS",
      pcs20GP: "1800pcs/20GP",
      pcs40GP: "3600pcs/40GP",
      pcs40HQ: "3900pcs/40HQ",
    },
  },
];

export const tapeProducts = [
  {
    id: 1,
    name: "Matt Gaffer Cloth Duct Tape",
    code: "GAF30",
    glue: "hot melt",
    thickness: "300±10um",
    tack: "20⬆#",
    peelAdhesive: "20N/in⬆",
    tensileStrength: "150N/in⬆",
    mainUsage: "Suitable for audio-visual equipment, TV, film industry, etc.",
    description:
      "Durable, high-tack tape for TV, film, and audio-visual equipment.",
    imageSrc: "https://legacy-lifeline.b-cdn.net/tapes/image-002.jpg",
  },
  {
    id: 2,
    name: "Printing Cloth Duct Tape",
    code: "CTP",
    glue: "hot melt",
    thickness: "210um",
    tack: "8⬆#",
    peelAdhesive: "18N/in⬆",
    tensileStrength: "65N/in⬆",
    mainUsage: "For DIY, decoration, gift wrapping, book protection, etc.",
    description: "Ideal for DIY projects, gift wrapping, and creative uses.",
    imageSrc: "https://legacy-lifeline.b-cdn.net/tapes/image-001.jpg",
  },
  {
    id: 3,
    name: "Fluorescent Gaffer Tape",
    code: "FGT",
    glue: "natural rubber",
    thickness: "300±10um",
    tack: "22⬆#",
    peelAdhesive: "18N/in⬆",
    tensileStrength: "14N/in⬆",
    mainUsage: "Used in stage lighting and audio-visual environments.",
    description:
      "High-visibility gaffer tape used in stage and lighting setups.",
    imageSrc: "https://legacy-lifeline.b-cdn.net/tapes/image-007.jpg",
  },
  {
    id: 4,
    name: "Camouflage Cloth Tape",
    code: "CCT",
    glue: "hot melt",
    thickness: "280±um",
    tack: "8⬆#",
    peelAdhesive: "14.7N/in⬆",
    tensileStrength: "343N/in⬆",
    mainUsage: "Suitable for outdoor protection and anti-slip applications.",
    description: "Camouflage tape for outdoor gear and anti-slip uses.",
    imageSrc: "https://legacy-lifeline.b-cdn.net/tapes/image-006.jpg",
  },
  {
    id: 5,
    name: "Double Sided Carpet Tape",
    code: "DESCT160",
    glue: "hot melt",
    thickness: "200um",
    tack: "20⬆#",
    peelAdhesive: "20⬆#",
    tensileStrength: "80N/in⬆",
    mainUsage: "Used for carpet stitching and pipe wrapping.",
    description: "Strong double-sided tape for carpets and pipe wrapping.",
    imageSrc: "https://legacy-lifeline.b-cdn.net/tapes/image-008.jpg",
  },
  {
    id: 6,
    name: "General Purpose Masking Tape",
    code: "MT14",
    glue: "rubber",
    thickness: "140-150um",
    tack: "8⬆#",
    peelAdhesive: null, // Default null
    tensileStrength: "50N/in⬆",
    mainUsage: "Suitable for decoration, electronics, and footwear industries.",
    description: "Versatile masking tape for various industries.",
    imageSrc: "https://legacy-lifeline.b-cdn.net/tapes/image-038.jpg",
  },
  {
    id: 7,
    name: "Self-Adhesive Kraft Paper Tape",
    code: "SKT1",
    glue: "hot melt",
    thickness: "120um",
    tack: "3⬆#",
    peelAdhesive: "8N/25MM",
    tensileStrength: "90N/in⬆",
    mainUsage: "Used for carton sealing and temporary packaging.",
    description: "Strong kraft paper tape for packaging and sealing.",
    imageSrc: "https://legacy-lifeline.b-cdn.net/tapes/image-039.jpg",
  },
  {
    id: 8,
    name: "Mono-Directional Filament Tap",
    code: "FTA13",
    glue: "hot melt",
    thickness: "0.13mm",
    tack: "5⬆#",
    peelAdhesive: "18N/25MM",
    tensileStrength: "280N/in⬆",
    mainUsage: "Used for packaging heavy objects and cable bundling.",
    description: "Filament tape for heavy-duty packaging and bundling.",
    imageSrc: "https://legacy-lifeline.b-cdn.net/tapes/image-104.jpg",
  },
  {
    id: 9,
    name: "Reflective Tape",
    code: "RTH02",
    glue: "acrylic",
    thickness: "0.11mm",
    tack: "10⬆#",
    peelAdhesive: null, // Default null
    tensileStrength: null, // Default null
    mainUsage: "Used for roads, cars, and various warning applications.",
    description: "Reflective tape for road safety and warning signs.",
    imageSrc: "https://legacy-lifeline.b-cdn.net/tapes/image-162.jpg",
  },
  {
    id: 10,
    name: "Double Sided PET Tape",
    code: "DSTPP-1",
    glue: "hot melt",
    thickness: "90um",
    tack: "8⬆#",
    peelAdhesive: "10N/in⬆",
    tensileStrength: null, // Default null
    mainUsage: "Used in leather, electronics, and automobile industries.",
    description:
      "Double-sided PET tape for leather, electronics, and auto uses.",
    imageSrc: "https://legacy-lifeline.b-cdn.net/tapes/image-088.jpg",
  },
];

export const ldpeProducts = [
  {
    id: 1,
    name: "2426H Film LDPE for Film and Bag",
    mfr: "2.0 g/10 min",
    materialType: "LDPE 2426H (Film Grade)",
    applications: [
      "Packaging films",
      "Bags and pouches for food, pharmaceuticals, consumer goods",
      "Daily necessities, industrial, and agricultural films",
    ],
    materialProperties: [
      "High clarity",
      "Low melt index",
      "Excellent drawdown properties",
      "Toughness and puncture resistance",
      "Good heat sealing properties",
    ],
    manufacturingProcess:
      "Produced through high-pressure polymerization, extruded into pellets or granules",
    technicalSpecifications: {
      mfi: "~2.0 g/10 min",
      other: [
        "Density",
        "Tensile strength",
        "Elongation at break",
        "Impact strength",
      ],
    },
    safetyAndEnvironmentalConsiderations: [
      "FDA approved for food packaging",
      "Proper handling and disposal required to minimize environmental impact",
    ],
    disclaimer: null,
    storageAndHandling: null,
    imageSrc: "https://legacy-lifeline.b-cdn.net/ldpe/2426H-ldpe.jpg",
    description:
      "LDPE 2426H is commonly used for making packaging films and bags with excellent toughness and clarity.",
  },
  {
    id: 2,
    name: "2426K Film LDPE for Plastic Bags",
    mfr: "4.0 g/10 min",
    materialType: "LDPE 2426K (Film Grade)",
    applications: [
      "Food packaging (wrapping films for cheese, meat, etc.)",
      "Agricultural films (greenhouse films, silage films, mulch films)",
      "Industrial films (shrink films, stretch films, protective films)",
      "Consumer products (shopping bags, garbage bags, disposable gloves)",
    ],
    materialProperties: [
      "Good optical properties (clarity and gloss)",
      "Good processability (blown film extrusion or cast film extrusion)",
      "Good mechanical properties (impact resistance, tear strength)",
      "Good sealability (easily heat-sealed)",
    ],
    manufacturingProcess: "Blown film extrusion or cast film extrusion",
    technicalSpecifications: {
      mfi: null,
      other: [
        "Good clarity and gloss",
        "Good impact resistance and tear strength",
        "Good heat-sealing properties",
      ],
    },
    safetyAndEnvironmentalConsiderations: null,
    disclaimer: null,
    storageAndHandling: null,
    imageSrc: "https://legacy-lifeline.b-cdn.net/ldpe/2426K-ldpe.jpg",
    description:
      "LDPE 2426K is used for films with excellent mechanical strength and sealability, ideal for food and agricultural applications.",
  },
  {
    id: 3,
    name: "2023JN Film LDPE for Shrink Film",
    mfr: "2.0 g/10 min",
    materialType: "SABIC LDPE 2023JN (Film Grade)",
    applications: [
      "General-purpose packaging films",
      "Medical, industrial, and agricultural films",
      "Shrink films, lamination films, produce bags, textile packaging",
      "Soft goods packaging, general-purpose bags, t-shirt carrier bags",
    ],
    materialProperties: [
      "High tensile strength and elongation at break",
      "Good optical properties (high gloss, good transparency)",
      "Excellent sealing properties (low sealing temperature, high hot tack strength)",
      "Contains slip and antiblock additives",
    ],
    manufacturingProcess: null,
    technicalSpecifications: {
      mfi: null,
      other: [
        "High gloss",
        "Transparency",
        "Low sealing temperature",
        "High hot tack strength",
      ],
    },
    safetyAndEnvironmentalConsiderations: null,
    disclaimer: "Not for use in pharmaceutical/medical applications",
    storageAndHandling: {
      storageConditions: "Store away from sunlight/heat",
      processWithin: "6 months of delivery",
      idealStorage: "Dry, <50°C",
    },
    imageSrc: "https://legacy-lifeline.b-cdn.net/ldpe/2023JN-ldpe.jpg",
    description:
      "SABIC LDPE 2023JN is ideal for shrink films and packaging with excellent sealability and mechanical properties.",
  },
  {
    id: 4,
    name: "Q281 Film LDPE for Engineering Plastics",
    mfr: "2.8 g/10 min",
    materialType: "LDPE Q281 (Film Grade)",
    applications: [
      "Film applications requiring puncture resistance",
      "Clear packaging films, food packaging films, stretch films",
    ],
    materialProperties: [
      "Toughness, flexibility, and clarity",
      "High impact strength",
      "Excellent flexibility and stretchability",
      "Good optical properties (high gloss, transparency)",
      "Good printability for logos and designs on packaging films",
    ],
    manufacturingProcess: "Blown film extrusion or cast film extrusion",
    technicalSpecifications: {
      mfi: null,
      other: [
        "High gloss",
        "Transparency",
        "High impact strength",
        "Good stretchability",
      ],
    },
    safetyAndEnvironmentalConsiderations: null,
    disclaimer: null,
    storageAndHandling: null,
    imageSrc: "https://legacy-lifeline.b-cdn.net/ldpe/Q281-ldpe.jpg",
    description:
      "LDPE Q281 is known for its toughness, flexibility, and puncture resistance, perfect for stretch and packaging films.",
  },
];

export const hdpeProducts = [
  {
    id: 1,
    name: "HDPE Plastic Film",
    mfr: "Manufacturer A",
    materialType: "High-Density Polyethylene (HDPE)",
    applications: ["Packaging", "Agriculture", "Construction"],
    properties: {
      density: "0.95 g/cm³",
      tensileStrength: "30 MPa",
      elongationAtBreak: "600%",
    },
    technicalSpecifications: {
      mfi: "2.5 g/10 min",
      other: ["UV Resistant", "Waterproof"],
    },
    safetyAndEnvironmentalConsiderations: [
      "Non-toxic",
      "Recyclable",
      "Low environmental impact",
    ],
    storageAndHandling: {
      storageConditions: "Store in a cool, dry place.",
      processWithin: "Use within 12 months of manufacture.",
      idealStorage: "Avoid direct sunlight and extreme temperatures.",
    },
    imageSrc: "https://legacy-lifeline.b-cdn.net/hdpe/hdpe-plastic-film.jpg",
  },
  {
    id: 2,
    name: "HDPE Pipes",
    mfr: "Manufacturer B",
    materialType: "High-Density Polyethylene (HDPE)",
    applications: ["Water Supply", "Sewage Systems"],
    properties: {
      density: "0.94 g/cm³",
      tensileStrength: "24 MPa",
      impactResistance: "Excellent",
    },
    technicalSpecifications: {
      mfi: "1.8 g/10 min",
      other: ["Corrosion Resistant", "Flexible"],
    },
    safetyAndEnvironmentalConsiderations: [
      "Non-toxic",
      "Can be recycled",
      "Minimal ecological footprint",
    ],
    storageAndHandling: {
      storageConditions: "Keep away from moisture.",
      processWithin: "Best used within 6 months.",
      idealStorage: "Store horizontally on a flat surface.",
    },
    imageSrc: "https://legacy-lifeline.b-cdn.net/hdpe/hdpe-pipes.jpg",
  },
  {
    id: 3,
    name: "Pipe Grade HDPE P6006",
    mfr: "SABIC",
    materialType: "Pipe grade HDPE",
    applications: [
      "Water Supply Pipes",
      "Gas Distribution Pipes",
      "Industrial Pipes",
      "Mining Pipes",
      "Irrigation Pipes",
    ],
    properties: {
      impactStrength: "Excellent",
      stiffness: "High",
      chemicalResistance: "Good",
      temperatureResistance: "High",
      processability: "Good",
    },
    technicalSpecifications: {},
    safetyAndEnvironmentalConsiderations: [],
    storageAndHandling: {},
    imageSrc: "https://legacy-lifeline.b-cdn.net/hdpe/hdpe-P6006.jpg", // Replace with actual image path
  },
  {
    id: 4,
    name: "Pipe Grade HDPE PN049",
    mfr: "SINOPEC SABIC",
    materialType: "Pipe grade HDPE",
    applications: ["Water Supply", "Mining", "Chemical Processing"],
    properties: {
      strength: "High",
      chemicalResistance: "Highly resistant",
      flexibility: "High",
      longevity: "Durable",
      costEffectiveness: "Cost-effective",
    },
    technicalSpecifications: {},
    safetyAndEnvironmentalConsiderations: [],
    storageAndHandling: {},
    imageSrc: "https://legacy-lifeline.b-cdn.net/hdpe/hdpe-PN049.jpg", // Replace with actual image path
  },
  {
    id: 5,
    name: "Pipe Grade HDPE 23050",
    mfr: "Unknown",
    materialType: "Pipe grade HDPE",
    applications: [
      "Sewage Systems",
      "Chemical Industry",
      "Construction Industry",
      "Agricultural Sector",
    ],
    properties: {
      density: "High",
      chemicalResistance: "Good",
      impactStrength: "Good",
      frictionCoefficient: "Low",
      uvResistance: "Excellent",
    },
    technicalSpecifications: {},
    safetyAndEnvironmentalConsiderations: [],
    storageAndHandling: {},
    imageSrc: "https://legacy-lifeline.b-cdn.net/hdpe/hdpe-23050.jpg", // Replace with actual image path
  },
  {
    id: 6,
    name: "Pipe Grade HDPE YGH041T",
    mfr: "Unknown",
    materialType: "Pipe grade HDPE",
    applications: [
      "Water Supply",
      "Drainage",
      "Sewage Systems",
      "Gas Distribution",
      "Chemical Transportation",
      "Mining",
      "Agriculture",
      "Trenchless Installations",
    ],
    properties: {
      abrasionResistance: "Excellent",
      chemicalResistance: "High",
      tensileStrength: "High",
      flexibility: "Good",
    },
    technicalSpecifications: {},
    safetyAndEnvironmentalConsiderations: [],
    storageAndHandling: {},
    imageSrc: "https://legacy-lifeline.b-cdn.net/hdpe/hdpe-YGH041T.jpeg", // Replace with actual image path
  },
  {
    id: 7,
    name: "Pipe Grade HDPE HE3488LSW",
    mfr: "BOROUGE",
    materialType: "Pipe grade HDPE",
    applications: [
      "Water Distribution",
      "Gas Distribution",
      "Industrial Piping",
      "Sewerage and Drainage",
    ],
    properties: {
      stressCrackResistance: "Excellent",
      toughness: "Good",
      flowRate: "High",
      hydrostaticStrength: "Excellent",
      environmentalStressCrackResistance: "Good",
    },
    technicalSpecifications: {},
    safetyAndEnvironmentalConsiderations: [],
    storageAndHandling: {},
    imageSrc: "https://legacy-lifeline.b-cdn.net/hdpe/hdpe-HE3488LSW.webp", // Replace with actual image path
  },
];

export const lldpeProducts = [
  {
    id: 1,
    name: "LLDPE 7042",
    manufacturer: "Unknown",
    properties: {
      flexibility: "Good flexibility, ideal for flexible films.",
      tensileStrength:
        "High tensile strength, withstands stress without breaking.",
      punctureResistance:
        "Excellent puncture resistance for packaging applications.",
      heatSealing: "Good heat sealing properties for easy packaging.",
      density: "Low density, lightweight material.",
      clarity: "Good clarity for transparency in applications.",
      slip: null,
      mechanicalProperties: null,
      stiffness: null,
      sealing: null,
      type: null,
      mfr: null,
    },
    applications: [
      "Flexible packaging films (bags, pouches, shrink films)",
      "Agricultural films (greenhouse films, mulch films, silage films)",
      "Construction films (vapor barriers, moisture barriers, protective films)",
      "Industrial applications (liners for ponds, landfills, protective covering)",
    ],
    packaging: "Not specified",
    imageSrc: "https://legacy-lifeline.b-cdn.net/lldpe/lldpe-7042.webp", // Add the image URL here
  },
  {
    id: 2,
    name: "SABIC 222WT",
    manufacturer: "Saudi Basic Industry Corporation",
    properties: {
      flexibility: null,
      tensileStrength: null,
      punctureResistance: null,
      heatSealing: null,
      density: null,
      clarity: null,
      slip: "High slip",
      mechanicalProperties: "Good mechanical properties",
      stiffness: "High stiffness",
      sealing: "Good sealing properties",
      type: "Ethylene-butene copolymer",
      mfr: "MFR is 1.9 g/10 min (190°C & 2.16 kg)",
    },
    applications: [
      "Cloth bags, carrier bags",
      "Thin layer coatings",
      "Refuse bags",
      "Meat packaging and other food packaging or lamination films",
      "General purpose consumer packaging",
      "Agricultural films (greenhouse films, silage bags, mulch films)",
    ],
    packaging: "Packed in 25kg bag",
    imageSrc: "https://legacy-lifeline.b-cdn.net/lldpe/lldpe-222wt.webp", // Add the image URL here
  },
  {
    id: 3,
    name: "LLDPE 7047",
    manufacturer: "Unknown",
    properties: {
      flexibility: "Good flexibility",
      tensileStrength: "High tensile strength",
      punctureResistance: "Puncture resistant",
      heatSealing: null,
      density: null,
      clarity: null,
      slip: null,
      mechanicalProperties: null,
      stiffness: null,
      sealing: null,
      type: null,
      mfr: null,
    },
    applications: [
      "Packaging products (bags, films)",
      "Construction films (water barriers, geomembranes)",
      "Agricultural films (greenhouse films, silage bags, mulch films)",
    ],
    packaging: "Not specified",
    imageSrc: "https://legacy-lifeline.b-cdn.net/lldpe/lldpe-7047.jpg", // Add the image URL here
  },
  {
    id: 4,
    name: "LLDPE 9047",
    manufacturer: "Unknown",
    properties: {
      flexibility: "Good flexibility for film production",
      tensileStrength: null,
      punctureResistance: null,
      heatSealing: null,
      density: null,
      clarity: null,
      slip: null,
      mechanicalProperties: null,
      stiffness: null,
      sealing: null,
      type: null,
      mfr: null,
    },
    applications: [
      "Packaging industry (films and bags)",
      "Construction industry (water barriers, geomembranes)",
      "Agriculture industry (greenhouse films, mulch films, silage bags)",
    ],
    packaging: "Not specified",
    imageSrc: "https://legacy-lifeline.b-cdn.net/lldpe/lldpe-9047.jpg", // Add the image URL here
  },
  {
    id: 5,
    name: "LLDPE F181",
    manufacturer: "Unknown",
    properties: {
      flexibility: "Good flexibility",
      tensileStrength: null,
      punctureResistance: "High puncture resistance",
      heatSealing: null,
      density: null,
      clarity: null,
      slip: null,
      mechanicalProperties: null,
      stiffness: null,
      sealing: null,
      type: null,
      mfr: null,
    },
    applications: [
      "Packaging industry (films and bags)",
      "Construction industry (geomembranes, construction films)",
      "Agriculture industry (greenhouse films, silage bags, mulch films)",
    ],
    packaging: "Not specified",
    imageSrc: "https://legacy-lifeline.b-cdn.net/lldpe/lldpe-f181.webp", // Add the image URL here
  },
];

export const pvcProducts = [
  {
    id: 1,
    name: "PVC SG5",
    description:
      "PVC SG5 exhibits good chemical stability, corrosion and water resistance, used in transparent name boards, pipes, PVC windows.",
    properties: {
      chemicalResistance: true,
      electricalInsulation: true,
      lowWaterAbsorption: true,
      thermalStability: null,
      weatherability: null,
      goodProcessingProperties: null,
      excellentElectricalProperties: null,
      goodFlameRetardantProperties: null,
    },
    applications: {
      construction: ["Pipes", "Profiles", "Windows", "Doors"],
      automotive: ["Hoses", "Cables", "Seals"],
      medical: ["Blood bags", "Tubing", "Medical devices"],
      electrical: ["Insulation for wires and cables"],
      consumerGoods: ["Toys", "Footwear", "Household items"],
    },
    imageSrc: "https://legacy-lifeline.b-cdn.net/pvc/pvc-sg5.jpg",
    conclusion:
      "PVC SG5 is a versatile material with a wide range of applications.",
  },
  {
    id: 2,
    name: "PVC SG3",
    description:
      "PVC SG3 is a chemical that is used in the production of insulation material, daily use plastics, industry packaging.",
    properties: {
      highChemicalResistance: true,
      weatherability: true,
      thermalStability: true,
      chemicalResistance: null,
      electricalInsulation: null,
      lowWaterAbsorption: null,
      goodProcessingProperties: null,
      excellentElectricalProperties: null,
      goodFlameRetardantProperties: null,
    },
    applications: {
      pipesAndFittings: ["Water supply", "Drainage", "Irrigation"],
      electricalApplications: ["Electrical insulation", "Wiring"],
      medicalApplications: ["Tubing", "Bags for blood and IV solutions"],
      automotiveApplications: ["Door panels", "Dashboards", "Seat covers"],
    },
    imageSrc: "https://legacy-lifeline.b-cdn.net/pvc/pvc-sg3.jpg",
    conclusion: "PVC SG3 is a versatile and widely used plastic resin.",
  },
  {
    id: 3,
    name: "PVC SG8",
    description:
      "PVC SG8 is a homogeneous white powder, without taste and smell, used for bottles, sheets, calendaring, rigid injection and moulding pipes.",
    properties: {
      goodProcessingProperties: true,
      excellentElectricalProperties: true,
      goodFlameRetardantProperties: true,
      chemicalResistance: null,
      electricalInsulation: null,
      lowWaterAbsorption: null,
      thermalStability: null,
      highChemicalResistance: null,
      weatherability: null,
    },
    applications: {
      construction: ["Pipes", "Fittings", "Profiles for windows and doors"],
      packaging: ["Blister packs", "Clamshells"],
      automotive: ["Dashboards", "Door panels", "Weatherstripping"],
    },
    imageSrc: "https://legacy-lifeline.b-cdn.net/pvc/pvc-sg8.jpg",
    drawbacks:
      "PVC is a non-biodegradable material that can release toxic chemicals during production and disposal.",
  },
  {
    id: 4,
    name: "PVC S1000",
    description:
      "PVC S1000 is a PVC resin the thermoplastic high molecular polymer, for automobile interiors, advertising light box, shoe soles, PVC pipes, PVC profiles.",
    properties: {
      highMechanicalStrength: true,
      goodChemicalResistance: true,
      easyToProcess: true,
      chemicalResistance: null,
      electricalInsulation: null,
      lowWaterAbsorption: null,
      thermalStability: null,
      weatherability: null,
      goodProcessingProperties: null,
      excellentElectricalProperties: null,
      goodFlameRetardantProperties: null,
    },
    applications: {
      buildingAndConstruction: [
        "Pipes",
        "Fittings",
        "Profiles",
        "Roofing sheets",
        "Flooring tiles",
        "Window frames",
      ],
      electricalAndElectronics: ["Cables", "Wires", "Insulators"],
      automotive: ["Door panels", "Dashboard", "Upholstery"],
      packaging: ["Bottles", "Containers", "Blister packs"],
    },
    imageSrc: "https://legacy-lifeline.b-cdn.net/pvc/pvc-s1000.jpg",
    conclusion:
      "PVC S1000 is a high molecular weight PVC resin with excellent properties for various applications.",
  },
];

export const woodProducts = [
  {
    id: 1,
    name: "Okoume Plywood",
    specifications: [
      "1220MM*2440MM",
      "1250MM*2500MM",
      "1220MM*2500MM",
      "915MM*2135MM",
      "Customizable",
    ],
    material: ["Poplar", "Combi", "Hardwood"],
    glue: ["E0", "E1", "E2", "Others"],
    minOrder: "1*40HQ",
    sizes: ["1220x2440mm", "1250x2500mm", "1250x3000mm", "Customized"],
    thickness: "2.7~30mm",
    faceBack: "Okoume",
    coreMaterial: [
      "Birch",
      "Eucalyptus",
      "Poplar",
      "Combi core",
      "Customizable",
    ],
    grade: [
      "B/BB",
      "B/C",
      "BB/BB",
      "BB/CP",
      "C/D",
      "CP/CP",
      "CP/C",
      "D/E",
      "E/F",
    ],
    glueOptions: ["Phenolic", "MDI", "E0", "E1", "E2"],
    moq: "20HQ",
    surfaceTreatment: ["Polished", "Non-Polished"],
    density: "620-750kg/m3",
    moistureContent: "8%~14%",
    usage: [
      "Furniture",
      "Laser Die Cutting",
      "Toy",
      "Loud Speaker",
      "Construction",
      "Flooring",
      "Others",
    ],
    certification: ["FSC", "CE", "EUTR", "CARB", "EPA"],
    description: {
      overview:
        "Okoume plywood, aka Marine plywood, is an African medium-sized hardwood tree.",
      standard: "BS 1088 Standard",
      construction:
        "Equal thickness okoume veneers in each layer, no voids, lightweight, strong, painted or bright finish.",
      ecoFriendly:
        "Okoume is a fast-growing tree and environmentally friendly.",
      durability:
        "Marine plywood resists delaminating, fungal attack, and water retention.",
      features: [
        "High bending strength and strong nail holding.",
        "Moisture-proof and tight construction. No rot or decay.",
        "Easy to nail, saw, cut, and drill.",
      ],
      manufacturingInfo: "Manufactured in China for over 30 years.",
    },
    imageSrc: "https://legacy-lifeline.b-cdn.net/wood/okoumePlywood.png",
  },
  {
    id: 2,
    name: "Pine Plywood",
    specifications: [
      "1220MM*2440MM",
      "1250MM*2500MM",
      "1220MM*2500MM",
      "915MM*2135MM",
      "Customizable",
    ],
    material: "Pine",
    glue: ["E0", "E1", "E2", "Others"],
    minOrder: "1*40HQ",
    sizes: ["1220x2440mm", "1250x2500mm", "1250x3000mm", "Customized"],
    thickness: "2.7~30mm",
    faceBack: "Pine, CDX",
    coreMaterial: [
      "Birch",
      "Eucalyptus",
      "Poplar",
      "Combi core",
      "Customizable",
    ],
    grade: [
      "B/BB",
      "B/C",
      "BB/BB",
      "BB/CP",
      "C/D",
      "CP/CP",
      "CP/C",
      "D/E",
      "E/F",
    ],
    glueOptions: ["Phenolic", "MDI", "E0", "E1", "E2"],
    moq: "20HQ",
    surfaceTreatment: ["Polished", "Non-Polished"],
    density: "620-750kg/m3",
    moistureContent: "8%~14%",
    usage: [
      "Furniture",
      "Laser Die Cutting",
      "Toy",
      "Loud Speaker",
      "Construction",
      "Flooring",
      "Others",
    ],
    certification: ["FSC", "CE", "EUTR", "CARB", "EPA"],
    description: {
      overview:
        "Pine Plywood is widely used in construction, often used as roofing and structural material.",
      gradesAvailable: "C/C Grade, C/D Grade, and higher grades.",
      construction: "Crafted with real natural wood and high-grade cores.",
      features: [
        "High bending strength and strong nail holding.",
        "Moisture-proof and tight construction. No rot or decay.",
        "Easy to nail, saw, cut, and drill.",
      ],
      manufacturingInfo: "Manufactured in China for over 30 years.",
    },
    imageSrc: "https://legacy-lifeline.b-cdn.net/wood/pinePlywood.png",
  },
  {
    id: 3,
    name: "F17 Film Faced Plywood",
    specifications: ["1220MM*2440MM*17MM", "1200MM*1800MM*17MM"],
    material: ["Birch", "Hardwood"],
    glue: ["WBP", "Phenolic", "Others"],
    minOrder: "1*40HQ",
    sizes: ["1220x2440mm", "1250x2500mm", "1250x3000mm", "Customized"],
    thickness: "17mm",
    faceBack: ["Brown Film", "Black Film", "Anti Slip Film", "Plastic Film"],
    coreMaterial: [
      "Birch",
      "Eucalyptus",
      "Poplar",
      "Combi core",
      "Customizable",
    ],
    grade: [
      "B/BB",
      "B/C",
      "BB/BB",
      "BB/CP",
      "C/D",
      "CP/CP",
      "CP/C",
      "D/E",
      "E/F",
    ],
    glueOptions: ["Phenolic", "WBP", "E0", "E1", "E2"],
    moq: "20HQ",
    surfaceTreatment: ["Polished", "Non-Polished"],
    density: "620-750kg/m3",
    moistureContent: "8%~14%",
    usage: [
      "Furniture",
      "Laser Die Cutting",
      "Toy",
      "Loud Speaker",
      "Construction",
      "Flooring",
      "Concrete Formwork",
    ],
    certification: ["FSC", "CE", "EUTR", "CARB", "EPA"],
    description: {
      overview:
        "F17 Film Faced Plywood is designed for formwork in concrete, building, and construction.",
      market: "Developed specifically for the Australian market.",
      qualityControl: [
        "In-house testing on key quality requirements.",
        "Independent grading and certification.",
        "FSC-certified veneer from sustainable forests.",
      ],
      features: [
        "Resists dilute acids and alkalines, increasing the number of uses.",
        "Smooth surface for excellent concrete finish.",
        "Reusable more than 50 times, reducing costs.",
      ],
      manufacturingInfo: "Manufactured in China for over 30 years.",
    },
    imageSrc: "https://legacy-lifeline.b-cdn.net/wood/film-faced.png",
  },
  {
    id: 4,
    name: "Wood Veneer",
    size: ["1270*2500mm", "1270*2200mm", "1270*1900mm", "970*1900mm"],
    thickness: "0.15mm-1.5mm",
    grade: ["A", "B", "C", "D"],
    technics: "Rotary cut veneer",
    usage: ["Furniture", "Building", "Decorate", "Flooring"],
    moistureContent: "8%–12%",
    moq: "1*20HQ",
    paymentTerm: ["T/T", "L/C"],
    supplyAbility: "10000cbm/month",
    certification: ["ISO", "CE", "CARB", "FSC"],
    description: {
      overview:
        "Wood veneer is a thin layer of wood used for decorative applications.",
      varieties: ["Maple", "Oak", "Ash", "Beech", "Cherry", "Walnut", "etc."],
      features: [
        "Variety of finishes available.",
        "Custom sizes and thickness.",
        "Sustainable materials with FSC certification.",
      ],
    },
    imageSrc: "https://legacy-lifeline.b-cdn.net/wood/okoumeVeneer.png",
  },
  {
    id: 5,
    name: "Melamine Door Skin",
    size: [
      "3.0*780*2150mm",
      "4.2*780*2150mm",
      "3.0*860*2150mm",
      "4.2*860*2150mm",
      "3.0*920*2150mm",
      "4.2*920*2150mm",
      "3.0*1020*2150mm",
      "4.2*1020*2150mm"
    ],
    thickness: "3mm-10mm",
    grade: ["A", "B"],  // Assuming two quality grades; you can adjust this.
    technics: "Melamine molded door skin with honeycomb paper filling and wooden frame for support",
    usage: ["Furniture", "Building", "Interior Design", "Doors"],  // Usage related to the product
    moistureContent: "5%–10%",  // Based on data from your description
    mqo: "1x 20ft",  // Assumed minimum order quantity based on the standard in the description
    paymentTerm: ["T/T", "L/C"],  // Standard payment terms
    supplyAbility: "10000pcs/month",  // Default monthly supply
    certification: ["ISO", "CE", "E1 Glue"],  // Certifications relevant to the product
    description: {
      overview:
        "Melamine door skins are made with HDF/MDF and are covered with melamine paper. They feature a honeycomb paper core for strength and are available in a variety of colors.",
      varieties: [
        "Wenge",
        "Beech",
        "Ash",
        "Mahogany",
        "Red Oak",
        "Red Walnut",
        "White Maple",
        "Teak",
        "Black Ash",
        "Sapele/Teak"
      ],  // Colors/varieties mentioned in your product
      features: [
        "Bright, attractive surface color that does not discolor.",
        "No spray painting required.",
        "Waterproof, scratch-resistant, and durable with no cracks, splits, or shrinkage.",
        "Eco-friendly, healthy, and long-lasting.",
      ],
      technicalData: {
        density: "Above 900kg/m3",
        moisture: "5% – 10%",
        waterAbsorptionRate: "<20%",
        lengthWidthTolerance: "±2.0mm",
        thicknessTolerance: "±2.0mm",
        modulusOfElasticity: "≥35Mpa"
      },  // Technical details from your provided data
      packing: {
        inner: "Each door skin is covered with shrink film.",
        outer: "Export wooden pallet packing with steel belt."
      },  // Packaging details provided
      loadingCapacity: "2700pcs = 1x20ft (18 pallets), 150pcs per pallet",  // Loading capacity as mentioned
    },
    imageSrc: "https://legacy-lifeline.b-cdn.net/wood/door-skin.png",
  }  
];

export const blenderProducts = [
  {
    id: 1,
    name: "BLENDER",
    model: "PGT-A31",
    description: "BLENDER PGT-A31",
    pcsPerCtn: 12,
    giftBoxSize: "21.4 x 12 x 15.5 Cm",
    cartonSize: "65.7 x 25.1 x 33 Cm",
    gw: 10.5,
    nw: 9.8,
    qty20GP: 6000,
    qty40GP: 13260,
    qty40HQ: 15000,
    imageSrc: "https://legacy-lifeline.b-cdn.net/blender/blender-1.jpg",
  },
  {
    id: 2,
    name: "BLENDER",
    model: "PGT-A12",
    description: "BLENDER PGT-A12",
    pcsPerCtn: 4,
    giftBoxSize: "24 x 18 x 34 Cm",
    cartonSize: "49.5 x 37.5 x 32.5 Cm",
    gw: 8.5,
    nw: 7.5,
    qty20GP: 1920,
    qty40GP: 3920,
    qty40HQ: 4260,
    imageSrc: "https://legacy-lifeline.b-cdn.net/blender/blender-2.jpg",
  },
  {
    id: 3,
    name: "ELECTRIC SKILLET",
    model: "PGT-1702",
    description: "10 Inch Multi-skillet 120V 60HZ 1200W",
    pcsPerCtn: 4,
    giftBoxSize: "35.5 x 15.2 x 34.7 Cm",
    cartonSize: "62.6 x 37 x 36.7 Cm",
    gw: 9.0,
    nw: 8.0,
    qty20GP: 1300,
    qty40GP: 2720,
    qty40HQ: 3180,
    imageSrc: "https://legacy-lifeline.b-cdn.net/blender/blender-3.jpg",
  },
];

export const cookwareProducts = [
  {
    id: 1,
    type: "Frying Pan",
    material: "Aluminum",
    size: "20 cm",
    diameter: "20 cm",
    nonStick: true,
    potCover: false,
    applicableStove: "Gas and Induction Cooker",
    handle: "Anti-Scalding Handle",
    modelNumber: "JG-0025",
    weight: null,
    shape: "Circle",
    brandName: null,
    feature: null,
    metalType: null,
    MOQ: null,
    name: "Non Stick Fry Pan",
    imageSrc: "https://legacy-lifeline.b-cdn.net/cookware/fry-pan-1.png"
  },
  {
    id: 2,
    type: "Frying Pan",
    material: "Aluminum",
    size: "20 cm",
    diameter: "20 cm",
    nonStick: true,
    potCover: false,
    applicableStove: "Gas and Induction Cooker",
    handle: "Wooden Handle",
    modelNumber: "JG-0025",
    weight: null,
    shape: "Circle",
    brandName: null,
    feature: null,
    metalType: null,
    MOQ: null,
    name: "Non Stick Fry Pan",
    imageSrc: "https://legacy-lifeline.b-cdn.net/cookware/fry-pan-2.jpg",
  },
  {
    id: 3,
    type: "Frying Pan",
    material: "Stainless Steel + Aluminum",
    size: "12 cm",
    diameter: "12 cm",
    nonStick: true,
    potCover: false,
    applicableStove: "Gas Cooker",
    handle: "Bakelite Handle",
    modelNumber: "JG-0009",
    weight: "1.5 kg",
    shape: "Circle",
    brandName: "Allnice",
    feature: "Sustainable",
    metalType: "Stainless steel",
    MOQ: 20,
    name: "Fry Pan",
    imageSrc: "https://legacy-lifeline.b-cdn.net/cookware/fry-pan-3.jpg",
  },
  {
    id: 4,
    type: "Frying Pan",
    material: "Stainless Steel",
    size: "20 cm",
    diameter: "20 cm",
    nonStick: true,
    potCover: false,
    applicableStove: "Gas and Induction Cooker",
    handle: null,
    modelNumber: "JG-0009",
    weight: "1.5 kg",
    shape: "Circle",
    brandName: null,
    feature: null,
    metalType: "Stainless steel",
    MOQ: null,
    name: "Fry Pan",
    imageSrc: "https://legacy-lifeline.b-cdn.net/cookware/fry-pan-4.jpg",
  },
  {
    id: 5,
    type: "Frying Pan",
    material: "Aluminum",
    size: "20 cm, 24 cm, 28 cm",
    diameter: "20/24/28 cm",
    nonStick: true,
    potCover: true,
    applicableStove: "Gas and Induction Cooker",
    handle: "Wooden Handle",
    modelNumber: "TG0015",
    weight: null,
    shape: "Circle",
    brandName: null,
    feature: null,
    metalType: null,
    MOQ: null,
    name: "Non Stick Deep Frying Pan",
    imageSrc: "https://legacy-lifeline.b-cdn.net/cookware/fry-pan-5.jpg",
  },
];

import React from "react";
import { motion } from "framer-motion";
import tw, { styled } from "twin.macro";
import { css } from "styled-components";
import { useParams } from "react-router-dom";
import { woodProducts } from "./../../helpers/categoryProducts";

// Styled Components
const ProductPageContainer = tw.div`max-w-screen-lg mx-auto my-16 p-8 rounded-lg shadow-lg bg-gradient-to-b from-blue-100 to-white`;
const ProductContainer = tw.div`grid grid-cols-1 md:grid-cols-2 gap-12 items-start`;
const ImageContainer = styled.div`
  ${(props) =>
    css`
      background-image: url("${props.imageSrc || "path/to/default-image.jpg"}");
    `}
  ${tw`w-full h-96 bg-center bg-cover rounded-lg shadow-xl`}
`;
const InfoContainer = tw.div`space-y-6`;
const ProductTitle = tw.h1`text-5xl font-extrabold text-blue-900 drop-shadow-lg`; // Updated color to blue-900
const ProductModel = tw.h2`text-2xl font-semibold text-gray-500`;
const ProductDescription = tw.p`text-lg text-gray-600`;
const SpecificationsTitle = tw.h3`text-2xl font-semibold text-blue-700 mt-4 border-b-2 border-blue-300 pb-2`; // Updated color to blue-700
const ProductSpecifications = tw.ul`list-disc list-inside text-gray-700 text-lg`;
const DetailItem = tw.li`py-1`;
const Button = tw(
  motion.button
)`px-8 py-3 rounded-lg bg-gradient-to-r from-purple-500 to-indigo-500 hover:bg-gradient-to-l hover:from-indigo-500 hover:to-purple-500 text-white text-lg mt-6 shadow-lg transition-transform transform hover:scale-105`;

export default function WoodProductPage() {
  const { id } = useParams();

  // Find the product by id, using parseInt to convert id to number
  const product = woodProducts.find((item) => item.id === parseInt(id)) || null;

  // If product is not found
  if (!product) {
    return (
      <>
        <h2>Product not found</h2>
      </>
    );
  }

  // Null checks before rendering product details
  const {
    name,
    description,
    imageSrc,
    thickness,
    faceBack,
    density,
    moistureContent,
    certification,
    minOrder,
    usage,
  } = product;

  // Safely check for required properties
  const productName = name || "Unknown Product";
  const productDescription =
    description && description.overview
      ? description.overview
      : "No description available.";
  const productImageSrc = imageSrc || "path/to/default-image.jpg";
  const productThickness = thickness || "N/A";
  const productFaceBack = faceBack || "N/A";
  const productDensity = density || "N/A";
  const productMoistureContent = moistureContent || "N/A";
  const productCertification =
    Array.isArray(certification) && certification.length > 0
      ? certification.join(", ")
      : "N/A";
  const productMinOrder = minOrder || "N/A";
  const productUsage =
    Array.isArray(usage) && usage.length > 0 ? usage.join(", ") : "N/A";

  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: 0.2 }}
    >
      <ProductPageContainer>
        <ProductContainer>
          {/* Product Image */}
          <ImageContainer imageSrc={productImageSrc} />

          {/* Product Info */}
          <InfoContainer>
            <ProductTitle>{productName}</ProductTitle>
            <ProductDescription>{productDescription}</ProductDescription>

            {/* Product Specifications */}
            <SpecificationsTitle>Product Specifications:</SpecificationsTitle>
            <ProductSpecifications>
              <DetailItem>
                <strong>Thickness:</strong> {productThickness}
              </DetailItem>
              <DetailItem>
                <strong>Face Back:</strong> {productFaceBack}
              </DetailItem>
              <DetailItem>
                <strong>Density:</strong> {productDensity}
              </DetailItem>
              <DetailItem>
                <strong>Moisture Content:</strong> {productMoistureContent}
              </DetailItem>
            </ProductSpecifications>

            {/* Additional Information */}
            <SpecificationsTitle>Additional Information:</SpecificationsTitle>
            <ProductSpecifications>
              <DetailItem>
                <strong>Certification:</strong> {productCertification}
              </DetailItem>
              <DetailItem>
                <strong>Min Order:</strong> {productMinOrder}
              </DetailItem>
              <DetailItem>
                <strong>Usage:</strong> {productUsage}
              </DetailItem>
            </ProductSpecifications>

            {/* Call to Action Button */}
            <Button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => (window.location.href = "/contact-us")}
            >
              Get a Quote
            </Button>
          </InfoContainer>
        </ProductContainer>
      </ProductPageContainer>
    </motion.div>
  );
}
import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";

import Profile from "../components/profile/Profile.jsx";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  // Dynamic data for services
  const services = [
    {
      title: "Sourcing and Procurement",
      description: "",
    },
    {
      title: "Logistics and Supply Chain Management",
      description: "",
    },
    {
      title: "Quality Control and Assurance",
      description: "",
    },
    {
      title: "Technical Support and Consultancy",
      description: "",
    },
  ];
  // Dynamic data for markets
  const markets = [
    {
      title: "Construction",
      description: "",
    },
    {
      title: "Automotive",
      description: "",
    },
    {
      title: "Packaging",
      description: "",
    },
    {
      title: "Healthcare",
      description: "",
    },
    {
      title: "Consumer Goods",
      description: "",
    },
  ];

  return (
    <AnimationRevealPage>
      <Header />
      <MainFeature1
        subheading={<Subheading></Subheading>}
        heading="Our History"
        description="With 24 years of trading experience, Legacy Lifeline has established itself as a leading player in the global textiles industry. Our expertise spans across textiles, home textiles, polymers, and raw materials, making us a one-stop-shop for all your textile needs. Founded in 2000, our company has grown from a small trading firm to a renowned name in the industry. Our journey has been marked by a commitment to quality, innovation, and customer satisfaction. Over the years, we have built strong relationships with suppliers and customers worldwide, enabling us to offer a diverse range of products at competitive prices."
        buttonRounded={false}
        primaryButtonText="See Portfolio"
        imageSrc="https://legacy-lifeline.b-cdn.net/aboutus/office.png"
      />

      <MainFeature1
        subheading={<Subheading></Subheading>}
        heading="Our Profile"
        description="Legacy Lifeline has warehouses in many cities in China, with large inventory and rich types of products, which can be delivered to the nearest port according to customers' needs. We proudly partner with renowned Chinese companies."
        buttonRounded={false}
        primaryButtonText="See Portfolio"
        textOnLeft={false}
        imageSrc="https://legacy-lifeline.b-cdn.net/aboutus/profile.png"
      />

      <Profile services={services} markets={markets} />

      <TeamCardGrid subheading={<Subheading></Subheading>} />
      <Features
        subheading={<Subheading></Subheading>}
        heading="Our Values"
        description=""
        cards={[
          {
            imageSrc: "https://legacy-lifeline.b-cdn.net/icons/support-icon.svg",
            title: "24/7 Support",
            description:
              "Our 24/7 support ensures you get help anytime, day or night, for uninterrupted service.",
          },
          {
            imageSrc: "https://legacy-lifeline.b-cdn.net/icons/shield-icon.svg",
            title: "Strong Teams",
            description:
              "Strong teams collaborate effectively, drive innovation, and achieve success through trust, communication, and shared goals.",
          },
          {
            imageSrc: "https://legacy-lifeline.b-cdn.net/icons/simple-icon.svg",
            title: "Customer Satisfaction",
            description:
              "Customer satisfaction is key for us to success, fostering loyalty, trust, and long-term relationships with businesses.",
          },
        ]}
        linkText=""
      />
      <Footer />
    </AnimationRevealPage>
  );
};
